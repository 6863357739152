.disc-checkbox-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #5F5959;
}

.bordered-checkbox-unselected {
    cursor: pointer;
    border: 1px solid #C9C9C9;
    padding: 4px;
    margin: 4px;
    text-align: center;
}

.bordered-checkbox-selected {
    cursor: pointer;
    border: 1px solid #7BAC16;
    color: white;
    background-color: #7BAC16;
    padding: 4px;
    margin: 4px;
    text-align: center;
}
