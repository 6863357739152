.dim14-form-element {
    display: flex;
    width: 100%;
    min-height: 30px;
    margin: 5px;
}

.dim14-validation-message-div {
    background-color: #f4f4f4;
    text-align: center;
    color: red;
    border: 1px solid #ccc;
    border-radius: 8px;
    min-height: 35px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.dim14-validation-message-div > table  {
    padding-left: 11px;
    padding-right: 8px;
}

.dim14-validation-message-div > table > tbody > tr {
    margin-bottom: 4px;
}

.tether-element {
    z-index: 1400;
}

.dim14-validation-message {
    padding-left: 20px;
    padding-right: 20px;
}

.dim14-form-label {
    font-weight: bold;
}

.dim14-has-error {
    color: #a94442;
}

.dim14-validation-message-table {
    border: none;
}

.dim14-validation-message {
    border: none !important;
}

.dim14-validation-message > *  {
    border: none !important;
}

.dim14-has-error input,
.dim14-has-error textarea,
.dim14-has-error button,
.dim14-has-error .PowerSelect,
.dim14-has-error .p-inputtext,
.dim14-has-error .p-dropdown,
.dim14-has-error .p-multiselect,
.dim14-has-error .Dim14FileUploader {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

input, textarea {
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
textarea {
    padding-top: 5px;
}
