.missing-patient-data {
    height: inherit;
}

.missing-patient-data .container {
    padding: 0;
}

.missing-patient-data div[class~=row] {
    height: 100%;
    margin: 0;
}

.missing-patient-data div[class~=patient-row] {
    width: 100%;
    height: 3.3em;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.missing-patient-data div[class~=patient-row-no-border] {
    width: 100%;
    height: 3.3em;
}

.missing-patient-data .patient-mrn {
    font-size: 14px;
    font-weight: lighter;
}

.missing-patient-data .patient-name {
    font-size: 85%;
    font-weight: bold;
}

.missing-patient-data .patient-missing-info {
    text-align: right;
    padding-right: 5px;
    padding-top: 3px;
    width: fit-content;
    float: right;
}

.missing-patient-data .patient-data-count {
    color: red;
    font-weight: bold;
}

.missing-patient-data .patient-missing-point {
    font-size: 14px;
}

