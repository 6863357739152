.round-button {
    margin-left: -5px;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 15px;
    border: 1px solid gainsboro;
    border-radius: 50%;
    color: black;
    text-align: center;
    text-decoration: none;
    background: white;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .15) !important;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.round-button:active {
    font-size: 0.9rem;
}

.InputModal {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
}

.whiteBackground {
    background-color: white;
}

.MuiOutlinedInput-input {
    padding: 8px !important;
    font-size: 13px !important;
}

.attachment-link {
    cursor: pointer;
    color: #84BE56;
    font-size: 12px;
    font-weight: bold;
}
