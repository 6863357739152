body .p-dialog {
    min-width: 500px;
}
body .p-dialog .p-dialog-titlebar{
    min-height: 50px;
}
.modalNoOption{
    margin-left: 20px;

}
.modalYesOption{
    margin-left: 20px;
}
.dim14modal.dim14modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}
