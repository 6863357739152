.treatment-received-card {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.treatment-schedule-date .MuiOutlinedInput-root{
    height: 25px;
    font-size: 12px;
    background: white;
}

.treatment-info-input-number {
    border: 1px solid #ccc !important;
    width: 100%;
    height: 25px;
}