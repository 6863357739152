.complaint-modal-transparent-button{
    background-color: transparent;
    border: transparent;
    width: 105px;
    color: #000000;
    padding-top: 0px;
}

.complaint-modal-transparent-button:hover{
    background-color: #f1f1f1;
    border: #e8e8e8;
    color: #000000;
}

.complaint-modal-transparent-button:focus{
    border: transparent;
    box-shadow: none;
}

.complaint-modal-transparent-button:not(:disabled):not(.disabled):active{
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    color: #000000;
}

.complaint-modal-transparent-button:not(:disabled):not(.disabled):active:focus{
    box-shadow: 0 0 0 0.2rem rgb(226, 226, 226);
}

.complaint-modal-transparent-button:disabled{
    background-color: transparent;
    border: transparent;
    width: 105px;
    color: #000000;
}

body .children-number-input .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: solid 2px #000 !important;
    border-color: #000 !important;
}