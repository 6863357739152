.p-multiselect .p-multiselect-label-container label,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    margin-bottom: 0;
}

.p-dropdown .p-dropdown-label {
    text-overflow: ellipsis;
}

.patient-consent-language-selection {
    background: transparent !important;
}

.patient-consent-language-selection .p-dropdown-label {
    background: transparent !important;
}

.patient-consent-language-selection .p-dropdown-trigger {
    background: transparent !important;
}

body .p-multiselect-panel {
    z-index: 2000 !important;
}

.light-color-bordered.p-dropdown,
.light-color-bordered.p-multiselect {
    border-color: #ccc;
}

.p-paginator .p-dropdown {
    border: 1px solid #a6a6a6 !important;
    height: 1.75rem !important;
    width: 4.5rem !important;
    padding: 0;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-bottom: 0rem;
    padding-top: 0.2rem;
}

.p-datatable .p-datatable-thead > tr > th.p-filter-column {
    text-align: left;
}

body .p-dropdown .p-dropdown-label {
    padding-right: 2.5em !important;
}