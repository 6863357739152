.PaymentHeader{
    margin-left: 0px;
    margin-bottom: 10px;
}

.PaymentMethodHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}

.PaymentMethod{
    background-color: #fbfbfb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    border-style: solid;
    border-width: thin;
    border-color: #eeeeee;
    border-radius: 10px;
}

.PaymentMethodSelected{
    border-style: solid;
    border-color: #7AAE00;
}

.PaymentInput{
    margin-top: 10px;
    font-size: 12px;
}

.PaymentIcon{
    display: flex;
    flex-direction: row;
}

.btn-success {
    background-color: #7AAE00 !important;
}

.btn-success:hover {
    background-color: #28a745 !important;
}

.p-datatable .p-datatable-wrapper .p-datatable-tbody .disabledRow {
    background-color: #EBEBEB;
}

.InvoiceTable td {
    overflow-wrap: anywhere;
}