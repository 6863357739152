.room-calendar-settings-top-bar{
    display: flex;
    flex-flow: row;
    width: 500px;
    margin-left: 30px;
    margin-bottom: 20px;
}

.room-calendar-settings-top-bar .top-bar-button {
    margin: 10px;
}

.settings{
    display: flex;
    flex-flow: column;
    background-color: #F9F9F9;
    margin-left: 30px;
    margin-right: 30px;
}

.settings .query-fields{
    display: flex;
    flex-flow: row;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid lightgray;

}

.settings-table {
    margin-left: 10px;
    margin-right: 10px;
}

.table-header {
    border-right: 1px solid lightgray;
}