.calendar-event {
    width: inherit;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
    border-style: solid;
    border-width: 3px;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.right-bottom {
    right: 0;
    bottom: 0;
}


.yellow-700 {
    color:#DEA43A;
}
.blue-700{
    color:#4788B0;
}

.cell-badge{
    width:20px;
    height: 20px;
    position: absolute;
    bottom:0;
    right:0;
    font-size:12px;
    display: flex;
    align-items: center;
    justify-content: center;

}