.card-body.expandable {
    opacity: 0;
    z-index: -1;
    max-height: 0;

    top: 100%;
    width: 100%;
    position: absolute;
    background: white;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;

    transition: max-height, 0.25s linear;
    -o-transition: max-height, 0.25s linear;
    -moz-transition: max-height, 0.25s linear;
    -webkit-transition:max-height, 0.25s linear;
}

.card-body.expandable.expanded {
    opacity: 1;
    z-index: 998;
    max-height: 250px;
    overflow: auto;
    transition: max-height, 0.25s linear;
    -o-transition: max-height, 0.25s linear;
    -moz-transition: max-height, 0.25s linear;
    -webkit-transition:max-height, 0.25s linear;
}

.AddReferenceAutoComplete > input {
    width: 100%;
}

.dim14querypanelbuttonsdiv{
    display: flex;
    flex-direction: column;
    align-items: center
}

.emr-icon {
    width: 56px;
    text-align: center;
}

.emr-icon img{
    width: 32px;
    cursor: pointer;
}

.appointment-table-cancellation-reason-icon {
    padding: 5px;
    width: 32px;
    height: 32px;
}


.expandable-table.expandable {
    opacity: 0;
    z-index: -1;
    max-height: 0;

    top: 100%;
    width: 100%;
    position: absolute;
    background: white;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;

    transition: max-height, 0.25s linear;
    -o-transition: max-height, 0.25s linear;
    -moz-transition: max-height, 0.25s linear;
    -webkit-transition:max-height, 0.25s linear;
}

.expandable-table.expandable.expanded {
    opacity: 1;
    z-index: 998;
    max-height: 500px;

    transition: max-height, 0.25s linear;
    -o-transition: max-height, 0.25s linear;
    -moz-transition: max-height, 0.25s linear;
    -webkit-transition:max-height, 0.25s linear;
}

.invisible-hero {
 visibility: collapse;
}