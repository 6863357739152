.visit-history{
    margin: auto;
}

.visit-history-table {
    width: 100%;
    word-wrap: break-word;
    table-layout:fixed;
    text-align: center;
}

.visit-history-table tr:nth-child(odd) {
    background-color: rgb(250,250,250);
}

.visit-history-table th {
    border: 1px solid #f6f6f6 !important;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #515151;
}

.visit-history-table tr {
    border: 1px solid #f6f6f6 !important;
    font-size: 14px;
}

.visit-history-table th {
    border: 1px solid #f6f6f6 !important;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
}

.visit-history-table td {
    border: 1px solid #f6f6f6 !important;
    font-size: 14px;
    padding: 5px 0;
}

.visit-history-content{
    width: 100%;
}

.visit-history-content ol {
    font-weight: bold;
    padding-left: 0px !important;
    list-style-type: none !important;
}

.visit-history-content li {
    list-style-type: none !important;
}

.riskLevelVisitHistoryLow{
    background-color: #A9DFBF;
}

.riskLevelVisitHistoryModerate{
    background-color: #ffd5b3;
}

.riskLevelVisitHistoryHigh{
    background-color: #E6B0AA;
}

.furtherAssessmentHeader span{
    font-size: 14px;
    font-weight: bold;
}

.furtherAssessmentContent span{
    font-size: 14px;
    display: block;
}

.visit-history-symptoms-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
}

.visit-history-multiple-content-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
}

.display-block span{
    display: block;
    margin-bottom: 10px;
}

.element-to-show{
    display: none;
}

.k-pdf-export .element-to-show{
    display: block;
}

.rangeOfMotionHistoryGreenColumn{
    background: rgba(197, 224, 181, 0.4);
}

.legacy-visit {
    background: #cecece !important;
}