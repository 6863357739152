.vitalSignsRow {
    margin-top: 20px;
    justify-content: left;

}
.rowTextBox {
    max-height: 26px;
    width: 100%;
}
.rowLabelDiv{
    width: 80px;
    height: 30px;
}
.rowLabel {
    font-weight: bold;
    width: 200%;
    font-size: 12px;
    padding-top:2px;
}
.rowRadio {
    margin-left: 15px;
    font-size: 11px;
}
.rowLocationLabel{
    margin-right: 200px;
}
.rowLocationDropdown{
    margin-left: 15px;
    margin-top: 6px;
    width: 120px;
    font-size: 14px;
    max-height: 120px !important;
}
.rowUnitDiv{
    margin-left: 10px;
    margin-top:10px;
}
.rowOverweightKeyword{
    font-size: 17px;
    font-weight: bold;
    margin-left: 13px;
    margin-top: 5px ;
}
checkboxLabel {
    font-size: 40px;
}
.label svg {
    width: 1em;
}
.MSKTextboxTitle {
    font-size: 14px;
    font-weight: bold;
}
.MSKTextboxTitleDiv{
    margin-top: 20px;
}
.MSKFindingsTextBox {
    max-height: 100px !important;
    height: 100px;
    width: 100%;
    min-height:100px !important ;
    margin-top: -5px;

}
.MSKFindingsdiv {
    height: 200%;

}
.MSKTextBox{
    font-size: 11px;
    width: 95%;
    min-height: 28px;
    max-height: 28px;
    height: 100%;
    margin-top: -5px;
}
.MSKTextBoxDiv{

}
.MSKList {
    margin-left: 5px;
}
MorseFallRiskAssessmentdiv {
    margin-bottom: 5px;
}
.radioChoiceDiv{
    margin-left: -60px;
}
.riskLevelNumberDiv{
    background-color: #e0e0e0;
    margin-top: 30px;
    max-height: 70px;
    height: 70px;
}
.riskLevelTextDivGreen{
    background-image: linear-gradient(to right,#229954, #A9DFBF);
    margin-top: 30px;
    max-height: 70px;
    height: 70px;
}
.riskLevelTextDivOrange{
    background-image: linear-gradient(to right,#ff8d2c, #ffd5b3);
    margin-top: 30px;
    max-height: 70px;
    height: 70px;
}
.riskLevelTextDivRed{
    background-image: linear-gradient(to right,#C0392B, #E6B0AA);
    margin-top: 30px;
    max-height: 70px;
    height: 70px;
}
.riskLevelNumberText{
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30px;
}
.riskLevelText{
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30px;
    color: white;
}
.riskLevelDiv{
    margin-top: -20px;
}
.riskLevelNoteDiv{
    margin-top: 10px;
}
.riskLevelNote{
    font-size: 12px;
}
.riskReferralSwitchDiv{
    margin-left: auto;
    margin-right: 0;
}
.riskFurtherAssessmentResults {
    margin-left: -10px;
    margin-top: 10px;
}
.riskViewFurtherAssessmentText{
    font-size: 13px;
    margin-left: 10px;
}
.riskViewFurtherAssessmentDiv{
    margin-bottom:15px ;
}
.MSKDiagnosisDropdown{
    width: 85%;
}

.rangeOfMotionDarkColumn{
    background: #d2d2d2;
}

.gaitRowLabel {
    font-size: 12px;
    font-weight: bold;
}
.gaitRowLabelDiv{
    margin-top:10px;
}
.gaitRowTextBox{
    width:90%;
}
.gaitAnalysisRowHeader {
    font-weight: bold;
    float: left;
    text-align: left;
}