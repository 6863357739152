.Dim14RadioDiv.Horizontal:first-child {
    display: inline;
}

.Dim14RadioDiv.Horizontal:not(:first-child) {
    display: inline;
}

.Dim14RadioDiv.Vertical {
    display: block;
}

.Dim14RadioDiv > input {
    margin-right: 5px;
    margin-left: 5px;
}
