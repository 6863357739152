.LeaveCalendar .SpecialityAndStaffFilter .MuiTypography-root {
    font-size: 14px !important;
}

.LeaveCalendar .rbc-row-content {
    overflow-y: overlay;
}

.LeaveCalendar .rbc-addons-dnd-row-body {
    height: inherit;
}

.LeaveCalendar .rbc-time-view, .LeaveCalendar .rbc-month-view{
min-height: calc(100vh - 328px)
}

.light-border {
    border: 1px lightgray solid;
    border-radius: 5px;
}

.draggable {
    cursor: pointer
}

.margin5 {
    margin: 5px;
}
.day-content{
    height: 200px  ;
    padding-top:20px;
}
.day-content-header{
    min-height: 2rem;
    display: block;
}
.circle-background {
    color: #fff;
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    font-size: 13px;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.rbc-addons-dnd-row-body {
    height: 300px
}

hr.solid {
    border-top: 1px solid lightgrey;
    margin: 10px -25px;
}

.attachment-label {
    cursor: pointer;
}

.LeaveCalendar .p-dropdown, .p-inputtext {
    font-size: 0.9rem !important;
}

.LeaveModal .MuiOutlinedInput-input {
    font-size: 13px !important;
}

.LeaveCalendar .rbc-date-cell {
    text-align: center;
}

.leave-event {
    height: inherit;
    width: inherit;
    background-color: rgb(132, 198, 183);
    border: none;
    font-weight: bold;
    text-align: center;
    font-size: 11px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leave-date {
    height: inherit;
    width: inherit;
    font-weight: bold;
    text-align: left;
    font-size: 11px;
}

.leave-day {
    background-color: lightgrey;
    text-align: center;
    font-size: 9px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.leave-separator {
    margin-top: 2px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.total-count {
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
}

.clear-all-button{
    font-size: 13px;
    font-weight: bold;
    background-color: lightgray;
    border: 1px lightgray;
    margin: 1px;
    width: 75px;
    height: 30px
}

.submit-for-approval-button{
    font-size: 13px;
    background-color: rgb(132, 190, 86);
    border: 1px lightgray;
    margin: 1px;
    width: 150px;
    height: 30px
}