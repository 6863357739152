.card-info-holder {
    padding: 10px;
    height: inherit;
}

.height-90 {
    height: 90%;
}

.vertical-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
}