.patient-detail-page {
    
}

.patient-detail-page .col, .patient-detail-page .col-1, .patient-detail-page .col-10, .patient-detail-page .col-11, .patient-detail-page .col-12, .patient-detail-page .col-2, .patient-detail-page .col-3, .patient-detail-page .col-4, .patient-detail-page .col-5, .patient-detail-page .col-6, .patient-detail-page .col-7, .patient-detail-page .col-8, .patient-detail-page .col-9, 
.patient-detail-page .col-md, .patient-detail-page .col-md-1, .patient-detail-page .col-md-10, .patient-detail-page .col-md-11, .patient-detail-page .col-md-12, .patient-detail-page .col-md-2, .patient-detail-page .col-md-3, .patient-detail-page .col-md-4, .patient-detail-page .col-md-5, .patient-detail-page .col-md-6, .patient-detail-page .col-md-7, .patient-detail-page .col-md-8, .patient-detail-page .col-md-9, 
.patient-detail-page .col-sm, .patient-detail-page .col-sm-1, .patient-detail-page .col-sm-10, .patient-detail-page .col-sm-11, .patient-detail-page .col-sm-12, .patient-detail-page .col-sm-2, .patient-detail-page .col-sm-3, .patient-detail-page .col-sm-4, .patient-detail-page .col-sm-5, .patient-detail-page .col-sm-6, .patient-detail-page .col-sm-7, .patient-detail-page .col-sm-8, .patient-detail-page .col-sm-9 {
    padding: 0 10px;
}

.breadcrumb {
    background: none;
}

.card {
    border-bottom-style: double;
    border-bottom-width: 2px;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important
}

.card.green-corner:before {
    width: 80%;
    height: 70px;
    z-index: -1;
    content: "";
    position: absolute;
    display: initial;
    top: -7px;
    left: -7px;
    border-top: 7px solid #97cd31;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #97cd31;
    border-right: 7px solid transparent;
}

.card.red-corner:before {
    width: 80%;
    height: 70px;
    z-index: -1;
    content: "";
    position: absolute;
    display: initial;
    top: -7px;
    left: -7px;
    border-top: 7px solid red;
    border-bottom: 7px solid transparent;
    border-left: 7px solid red;
    border-right: 7px solid transparent;
}

.card .card-header {
    display: flex;
    justify-content: space-between;
}

.card .card-header .card-header-actions .btn-group .btn {
    margin: 0 !important;
    padding: .2rem .5rem;
    border-radius: 0;
}

.card .card-header .card-header-actions .btn {
    margin: 0 10px 0 0 !important;
    padding: .2rem .5rem;
    border-radius: 0;
}

.card .card-body.small-font {
    white-space: nowrap;
    font-size: 1rem;
}

.card .card-body .profile-image-container {
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: 0 auto;
    border: 1px solid rgba(0,0,0,.125);
}

.card .card-body .profile-image-container .profile-image {
    width: 100%;
    min-height: 80px;
    min-width: 80px;
    max-height: 80px;
    max-width: 80px;
}

.card .card-body .profile-image-container .profile-image-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    padding-top: 50px;
    position: absolute;
    transition: .3s ease;
    background-color: #DDD;
}

.card .card-body .profile-image-container:hover .profile-image-overlay {
    opacity: 0.6;
}

.card .card-body .profile-image-container .profile-image-overlay .fa-camera {
    font-size: 1.5rem;
}

.patient-detail-page .card .card-body .form-group {
    margin-bottom: 0;
}

.patient-detail-page .card .card-body .form-group .control-label {
    margin: 0;
    font-weight: bold;
}

.patient-detail-page .card .card-body.line-wrapper {
    padding: .4rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card .card-body.special-status-wrapper {
    border-top: 1px solid rgba(0,0,0,.125);
    padding: .75rem 1.25rem;
    display: flex;
    justify-content: space-between;
}

.special-status-icon {
    width: 30px;
    height: 30px;
    margin: 5px 10px;
    cursor: pointer;
}
.DISCIconStatus{
    width: 17px;
    height: 17px;
    cursor: pointer;
}
.special-status-icon.selectable {
    border: 1px solid #DDD;
    padding: 10px;
    width: 50px;
    height: 50px;
}

.special-status-icon.selectable:hover {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
}

.special-status-icon.selected {
    -webkit-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
}

.special-status-icon.selected:hover {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(255,0,0,1);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(255,0,0,1);
    box-shadow: inset 0px 0px 10px 5px rgba(255,0,0,1);
}

.special-status-icon-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.special-status-icon-language-label {
    font-size: 12px;
    font-weight: bold;
    color: #999999;
    margin-top: -27px;
    margin-left: 18px;
}

.patient-detail-page .card .fa-edit {
    color: #888;
    padding: 0;
    cursor: pointer;
    font-size: 1.4em;
    vertical-align: middle;
}

.patient-detail-page .card .fa-angle-double-up,
.patient-detail-page .card .fa-angle-double-down {
    color: #333;
    padding: 0;
    cursor: pointer;
    font-size: 1.5em;
    vertical-align: middle;
}

.patient-detail-page .no-show-value {
    margin: 10px;
    text-align: center;

}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #6DBA17 !important;
    color: #333333;
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
    background-color: #ffffff;
}

body .p-datatable .p-datatable-tbody > tr:focus + tr > td {
    box-shadow: none !important;
}
body .p-datatable .p-datatable-tbody > tr:focus > td {
    box-shadow: none !important;
}
body .p-datatable .p-datatable-tbody > tr:focus > td:first-child {
    box-shadow: none !important;
}
body .p-datatable .p-datatable-tbody > tr:focus > td:last-child {
    box-shadow: none !important;
}

@keyframes fadeAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.border-2 {
    border-style: ridge;
    border-width: 2px;
}

.action-drop-down:before {
    content: '\2807';
    font-size: 20px;
}


@media screen and (max-width: 991px) {
    .lg-row-margin {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .md-row-margin {
        margin-top: 2rem;
    }
}



#add-emergency-contact  .MuiDialogContent-root, #add-emergency-contact .MuiDialog-paper, #add-emergency-contact .MuiDialog-paperScrollBody,
#update-emergency-contact .MuiDialogContent-root, #update-emergency-contact .MuiDialog-paper, #update-emergency-contact.MuiDialog-paperScrollBody
{
    overflow-y: unset;
}

