.dim14-datepicker .MuiOutlinedInput-input {
    font-size: 13px;
    padding: .75em;
}

.dim14-datetimepicker-toolbar.MuiPickersToolbar-toolbar {
    height: 60px;
    background-color: #28a745;
}

.dim14-datetimepicker-toolbar .dim14-datetimepicker-time {
    border: 1px solid #59791d;
    padding: .1em .5em;
    border-radius: 4px;
}