.no-current-time-indicator .rbc-current-time-indicator {
    display: none;
}

.copy-schedule-calendar .rbc-row.rbc-row-resource {
    border: none;
}

.copy-schedule-calendar .rbc-header {
    border: none;
}