.cancellation-reason-icon {
    margin: 0 10px;
    cursor: pointer;
    border: 1px solid #DDD;
    padding: 10px;
    width: 50px;
    height: 50px;
}

.cancellation-reason-icon.selected {
    -webkit-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
}

.cancellation-reason-icon:not(.selected):hover {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
}

.cancellation-reason-icon-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}