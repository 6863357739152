.MuiOutlinedInput-input {
    font-size: 11px !important;
}

.narrow-dropdown-label {
    width: 100%;
    height: 25px;
    font-size: 10px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.narrow-dropdown-label .p-dropdown-label {
    padding: 0 0 0 2px;
}
.row-responded {
    background-color: #6DBA17 !important;
}
.pre-approval-table .p-datatable-row td{
    font-size: 13px;
}

.InsuranceTable td{
    font-size: 13px;
    word-break: break-word;
}

.InsuranceTable th{
    word-break: break-word;
}