.emr-record-header .row {
    margin: 0px;
}

.emr-form-save {
    display: flex;
    flex-direction: row-reverse;
    height: 50px;
}

.content-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
}

.content-col-12 {
    max-width: calc(49% - 10px);
    padding: 3px;
    margin: 10px;
    background-color: rgb(245,245,245);
    border-radius: 12px;
}

.content-col-6 {
    flex: 0 0 50%;
    max-width: calc(49% - 10px);
    padding: 3px;
    margin: 10px;
    background-color: rgb(245,245,245);
    border-radius: 12px;
}
.content-col-6:first-child {
    padding-left: 0;
    margin-left: 0;
}
.content-col-6:last-child {
    padding-right: 0;
    margin-right: 0;
}

.content-col-12 {
    flex: 0 0 100%;
    max-width: calc(98% - 6px);
    padding: 3px;
    margin: 3px;
    background-color: rgb(245,245,245);
    border-radius: 12px;
}

.bg-none {
    background: none;
}

.content-wrapper {
    margin: 15px;
    padding: 5px;
}

.view-wrapper {
    height: 100%;
}

.content-card {
    height: 100%;
    padding: 5px;
    margin: 15px auto auto auto;
    width: 99%;
    background-color: white;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;
    border-radius: 10px;
}

.content-card-title {
    justify-content: center;
    margin: auto;
    text-align: center;
}

.content-clickable {
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.content-clickable:hover {
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;
}

.content-label {
    margin: auto auto 10px 5px;
    font-family: 'Lato';
    font-size: 24px;
    font-weight: bold;
}
.content-label.large {
    font-size: 28px;
    margin-left: 25px;
}

.content-row input[class$=dim14textinput] {
    height: auto;
}

.emr-gray-select-container {
    margin-bottom: 2px;
}

.emr-gray-select__control {
    min-height: 25px !important;
    max-height: 25px !important;
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    background-color: rgb(250,250,250) !important;
}

.emr-gray-select__value-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 1px;

}

.emr-gray-select__indicator-separator {
    visibility: hidden;
}

.emr-gray-select__indicators {
    padding: 0px !important;
}

.emr-gray-select__dropdown-indicator {
    color: #4D4D4D !important;
    padding: 1px !important;
}

.emr-gray-select__clear-indicator {
    padding: 0px !important;
}

.emr-white-select-container {

}

.emr-white-select__control {
    min-height: 25px !important;
    max-height: 25px !important;
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.emr-white-select__value-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 1px;
    font-size:12px
}

.emr-white-select__indicator-separator {
    visibility: hidden;
}

.emr-white-select__indicators {
    padding: 0px !important;
}

.emr-white-select__dropdown-indicator {
    color: #4D4D4D !important;
    padding: 1px !important;
}

.emr-white-resized-select-container {

}

.emr-white-resized-select__control {
    min-height: 28px !important;
    max-height: 28px !important;
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.emr-white-resized-select__value-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 1px;
    font-size:12px
}

.emr-white-resized-select__indicator-separator {
    visibility: hidden;
}

.emr-white-resized-select__indicators {
    padding: 0px !important;
}

.emr-white-resized-select__dropdown-indicator {
    color: #4D4D4D !important;
    padding: 1px !important;
}

.emr-white-select__clear-indicator {
    padding: 0px !important;
}

.emr-readonly-date input{
    padding: 6px;
    font-size: 12px;
}

.emr-radio-group {
    display: unset;
}

.emr-radio-group div[class*="checked"] {
    color: #73AD21 !important;
}

.emr-radio-group svg[class^="MuiSvgIcon-root"] {
    font-size: 14px;
}

.emr-radio-group span[class^="MuiTypography-root"] {
    font-size: 14px;
}

.emr-background {
    padding: 3px;
    margin: 3px;
    background-color: rgb(245,245,245);
}

.emr-plus-button {
    padding: 3px 8px;
}

.emr-add-button{
    font-size: 8px;
    padding: 0px 3px;
    border-radius: 50%;
}

.emr-transparent-delete-button {
    background: none;
    padding: 0px;
    border: none;
    height: 0;
}

.emr-table {
    width: 100%;
    text-align: center;
}

.emr-table tr:nth-child(odd) {
    background-color: rgb(250,250,250);
}

.emr-table th {
    border: 1px solid #f6f6f6 !important;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #515151;
}

.emr-table tr {
    border: 1px solid #f6f6f6 !important;
    font-size: 14px;
    color: #515151;
}

.emr-table td {
    border: 1px solid #f6f6f6 !important;
    padding: 5px 0;
}

.emr-table .p-autocomplete {
    text-align: left;
}

.emr-table .p-autocomplete-multiple-container {
    padding: 0px !important;
}

.w-full-20 {
    width: calc(100% - 20px)
}

.range-of-motion-table {
    width: 100%;
    text-align: center;
    font-size: 12px
}

.range-of-motion-table tr:nth-child(2) {
    background-color: rgb(245,245,245);
}

.range-of-motion-table th {
    border: 1px solid #f6f6f6 !important;
    padding: 5px;
}

.range-of-motion-table tr {
    border: 1px solid #f6f6f6 !important;
}

.range-of-motion-table td {
    border: 1px solid #f6f6f6 !important;
}

.emr-table-head {
    text-align: left;
    font-weight: bold;
}

.emr-round-button-middle-right-wrapper {
    position: absolute;
    top: 12%;
    right: -12px;
    cursor: pointer;
    z-index: 9999999;
}

.emr-round-button-middle-right-add-wrapper {
    position: absolute;
    top: 18%;
    right: -12px;
    cursor: pointer;
    z-index: 9999999;
}

.emr-round-button {
    display:block;
    width:20px;
    height:20px;
    line-height:15px;
    border: 1px solid gainsboro;
    border-radius: 50%;
    color:black;
    text-align:center;
    text-decoration:none;
    background: white;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;
    font-size:20px;
    font-weight:bold;
}
.emr-round-button.plus {
    padding-top: 2px;
    color: white;
    background-color: #6DBA17;
}

.emr-round-button:hover {
    background: gainsboro;
    z-index:100;
}

.emr-in-table-select-container {
    margin-bottom: 2px;
    border: 0px !important;
}

.emr-in-table-select__control {
    min-height: 25px !important;
    max-height: 25px !important;
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    border: 0px !important;
}

.emr-in-table-select__value-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 1px;
    font-size:12px
}

.emr-in-table-select__indicator-separator {
    visibility: hidden;
}

.emr-in-table-select__indicators {
    padding: 0px !important;
}

.emr-in-table-select__dropdown-indicator {
    color: #4D4D4D !important;
    padding: 1px !important;
}

.MuiExpansionPanelSummary-content {
    display: block !important;
    text-align: center !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    background-color: rgb(243, 244, 246);
}

.div-list-wrapper {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid gainsboro;
    width: 100%;
    min-height: 50px;
}

.div-list-item {
    margin: 2px;
    padding: 3px;
    font-size: 14px;
    border: 1px solid white;
    background-color: white;
    border-radius: 10px;
    display: flex;
    height: fit-content;
}

.btn-success.disabled, .btn-success:disabled {
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
}

.MorseFallRiskAssessmentdiv {
       margin-bottom: 5px;
    }
.radioChoiceDiv{
        margin-left: -60px;
     }
.riskLevelNumberDiv{
        background-color: #e0e0e0;
        margin-top: 30px;
        max-height: 70px;
        height: 70px;
     }
.riskLevelTextDivGreen{
        background-image: linear-gradient(to right,#229954, #A9DFBF);
        margin-top: 30px;
        max-height: 70px;
        height: 70px;
     }
.riskLevelTextDivOrange {
    background-image: linear-gradient(to right, #ff8d2c, #ffd5b3);
    margin-top: 30px;
    max-height: 70px;
    height: 70px;
}
.riskLevelTextDivRed {
     background-image: linear-gradient(to right, #C0392B, #E6B0AA);
     margin-top: 30px;
     max-height: 70px;
     height: 70px;
 }
.riskLevelNumberText {
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30px;
}
riskLevelText {
    font-size: 15px;
}
.riskLevelDiv{
        margin-top: -20px;
}
.riskLevelNoteDiv {
    margin-top: 10px;
}
.riskReferralSwitchDiv {
      margin-left: auto;
      margin-right: 0;
  }
riskFurtherAssessmentResults {
    margin-left: -10px;
    margin-top: 10px;
}
.riskViewFurtherAssessmentText {
    font-size: 13px;
    margin-left: 10px;
}
.riskViewFurtherAssessmentDiv {
    margin-bottom: 15px;
}
.riskViewRowLabel{
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30px;
    color: white;
}

.no-visit-text{
    font-size: 25px;
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
}

.attachment-label {
    cursor: pointer;
}

#upload-attachment {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.link-column {
    width: 170px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.attachment-list {
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

nav {
    margin-top: 8px;
    padding: 10px 8px 0 8px;
    background-color: rgb(245,245,245);
    border-radius: .25rem .25rem 0 0;
}

nav>a.nav-item {
    font-weight: bold;
    font-size: 14px;
    padding: 6px 20px 8px 20px;
    color: #495057;
}

nav>a.nav-item:hover {
    font-weight: bold;
    font-size: 14px;
    padding: 6px 20px 8px 20px;
    color: #495057;
}

nav>a.nav-item:first-child {
    margin-left: 10px;
}

.text-align-left {
    text-align: left;
}

.height-unset {
    height: unset;
}


.text-color-red {
    color:red;
}

.white-space-pre-line {
    white-space: pre-line;
}

.text-color-red {
    color:red;
}

.white-space-pre-line {
    white-space: pre-line;
}

.referral-autocomplete.p-multiselect .p-multiselect-panel {
    min-width:max-content;
}

.referral-autocomplete.p-multiselect .p-multiselect-label {
    margin: 0;
    text-align: left;
}

.cpt-style .p-multiselect ul {
    border: 1px solid #ccc;
}
.cpt-style .p-multiselect ul:hover {
    border-color:#ccc !important;
}
.cpt-style .p-autocomplete-input-token{
    padding:0 0 4px 0 !important;
}

.communication-files-list {
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 95%;
}

.communication-files-link-column {
    width: 350px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.communication-tasks-list {
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.communication-tasks-column {
    width: 500px;
    color: black;
    cursor: pointer;
}

.communication-grid {
    width: 90%;
    margin: 5px;
}

.CommunicationModal {
    width: 600px;
}

.CommentModal {
    width: 600px;
}

.pendingTask {
    margin-left: 5px;
}

.alertTask {
    margin-left: 5px;
    color: red;
}

.doneTask {
    margin-left: 5px;
    color: green;
}