.login-page:before {
    content:'';
    background-image: url("/disc.webp");
    background-size: cover;
    background-position: center;
    position: absolute; top: 0; left: 0;
    width: 100%;
    height: 100%;
}
.login-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    overflow: hidden;
}
.login-form {
    color: white;
    font-weight: bold;
    background-color: rgba(58, 60, 60, 0.8);
    width: 100%;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 20px 11px -20px #000;

}

.login-form .has-error {
    color: red;
    font-weight: bold;
}
