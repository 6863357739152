.insurance-card-container {
    border-radius: 10px;
    border: 1px outset;
    height: 190px;
}

.insurance-card-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}