.clinic-select-wrapper {
    display: flex;
    align-items: center;
    /*font-size: 13px;*/
}

.clinic-select-wrapper label {
    margin: 0 5px;
    min-width: fit-content;
}

.clinic-select-popover {
    padding: 10px;
    min-width: 350px;
    font-size: 14px;
}

.clinic-select-wrapper #clinic-menu {
    z-index: 999;
}

.clinic-select-icon-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
}

.clinic-select-icon {
    cursor: pointer;
    border: 1px solid #DDD;
    padding: 10px;
    width: 65px;
    height: 85px;
    padding-bottom: 30px;
}

.clinic-select-icon-wrapper div {
    display: flex;
    cursor: pointer;
    flex-direction: column;
}

.clinic-select-icon-wrapper label {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: -30px;
}

.clinic-select-icon.selected {
    -webkit-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 1px 5px rgba(0,255,17,0.5);
}

.clinic-select-icon:not(.selected):hover {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
    box-shadow: inset 0px 0px 10px 5px rgba(0,255,17,0.5);
}

.clinic-select-modal-footer {
    padding: 5px;
    text-align: center;
    border-top: 1px solid #DDD;
    margin: 20px -10px -10px -10px;
}



