.BorderedRegion {
    width: 100%;
    border: 1px solid black;
    margin-top: 20px;
}

.SideBySide{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.GenderDropdown.p-dropdown{
    min-width: 140px !important;
}

.WhenDidItStartDropdown.p-dropdown{
    min-width: 90px !important;
}

.DropdownBorderColor.p-dropdown{
    border: 1px solid #ccc !important;
    border-radius: 4px;
}

.DropdownDisabledBackground .p-inputtext{
    background: #f4f4f4 !important;
}

.DropdownDisabledBackground.p-dropdown .p-dropdown-trigger{
    background: #f4f4f4 !important;
}

.DropdownDisabledBackground.p-dropdown{
    border: 1px solid #999999 !important;
    border-radius: 4px;
    background: #fafafa;
}

.PhoneInputDisabledBackground.react-tel-input :disabled{
    background: #fafafa !important;
}

.AutoCompleteDisabledBackground body .p-disabled, body .p-component:disabled{
    background: #f4f4f4;
}

.DateInputDisabledBackground .MuiInputBase-input.Mui-disabled{
    background: #f4f4f4;
    border: 1px solid #ccc;
}

.TextInputDisabled .dim14textinput{
    color: #b1b1b1;
}

.scrollbar
{
    background: #F5F5F5;
    position: relative;
    scrollbar-color: #007bff #F5F5F5;
    scrollbar-width: thin;
}

#dim14-consent-form-scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

#dim14-consent-form-scrollbar::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

#dim14-consent-form-scrollbar::-webkit-scrollbar-thumb
{
    background-color: #007bff;
}

.allergies-dropdown {
    width: 12rem !important;
}

.allergies-dropdown .PowerSelect {
    height: 100%;
}

.form-body {
    max-width: 950px;
    min-width: 800px;
}

.arabic-form {
    direction: rtl;
    text-align: right;
}

.arabic-form .p-dropdown-items .p-dropdown-item {
    text-align: right;
}

.arabic-form .react-tel-input .selected-flag .arrow{
    left: -30px;
}

.arabic-form .family-select .p-dropdown-label.p-inputtext{
    padding-right: 2em;
}

.arabic-form .reference-dropdown label.p-dropdown-label{
    padding-right: 3em;
}

.header-line{
    border-top: 2px solid #000000;
    box-shadow: 0px 2px 2px #797979;
}

.describe-symptoms input[type=checkbox], input[type=radio]{
    align-self: center;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

.describe-symptoms label{
    margin-bottom: 0;
    align-self: center;
}

.arabic-form .react-tel-input .form-control{
    padding-right: 4rem;
    direction: ltr;
    text-align: right;
}

.arabic-form .allergy-radio-input{
    text-align: left !important;
}





