.Dim14FileUploader {
    border: solid 2px #000000;
    border-radius: 10px;
    padding: 10px;
}

.Dim14FileUploader .selectedLabel {
    color: #037bc6;
}

.Dim14FileUploader .selectedLabel:hover {
    color: #0159a4;
    cursor: pointer;
    text-decoration: underline;
}

.Dim14FileUploader .noSelectionLabel {
    color: #e08000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Dim14FileUploader .icon {
    color: #037bc6;
}

.Dim14FileUploader .icon:hover {
    color: #0159a4;
    cursor: pointer;
}

.Dim14FileUploader .imagePreview {
    max-height: 50px;
    max-width: 100px;
    vertical-align: middle;
}

.Dim14FileUploader .imagePreview:hover {
    cursor: pointer;
}

.Dim14FileUploader .selectedUL {
    padding-left: 0;
}

.Dim14FileUploader .selectedLI {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
}

.Dim14FileUploader .imagePreviewDiv {
    width: 100px;
    float: left;
    text-align: center;
    align-self: center;
}

.Dim14FileUploader .labelDiv {
     align-self: center;
 }

.Dim14FileUploader .closeDiv {
    width: 15px;
    float: right;
    text-align: center;
    align-self: center;
}
