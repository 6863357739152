.rbc-event-label {
    display: none;
}

.rbc-day-slot .rbc-time-slot {
    border: none !important;
}

.rbc-day-slot .rbc-events-container {
    margin-right: 1px !important;
}

.rbc-events-container {
    margin-right: 1px;
}

.rbc-today-bg-none .rbc-today {
    background: none;
}

.rbc-show-header-single-day .rbc-time-header-cell-single-day {
    display: inherit !important;
    height: 100%;
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
    margin-right: unset;
}

/* START min-width override */
.appointment-calendar .rbc-day-slot {
    min-width: 180px !important;
}

.appointment-calendar .rbc-header {
    min-width: 180px !important;
}

.appointment-calendar .rbc-header,
.appointment-calendar .rbc-day-bg {
    width: 180px !important;
}

.appointment-calendar .rbc-time-header-content + .rbc-time-header-content {
    margin-left: 0px !important;
    margin-right: -1px;
}

.custom-resource-header{
    font-size: 12px;
}

.PowerSelect__Tether{
    z-index: 1600;
}

.agenda-view {
    height: auto !important;
    overflow: auto;
    min-width: 1600px !important;
}
/* END min-width override */