.restriction-panel{

}

@media screen and (max-width: 1200px) {
    .restriction-panel {
        margin-top: 20px;
    }
}

.add-restriction-modal{
    min-width: 480px;
}