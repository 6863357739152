.emr-home-table thead {
    display: none;
}

.data-table-header-wrapper {
    display: inline-block;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.start-visit-button.btn-success {
    background-color: white !important;
    color: #7AAE00 !important;
}

.start-visit-button.btn-success:hover {
    background-color: #7AAE00 !important;
    color: white !important;
}

.start-visit-button.btn-success.disabled, .btn-success:disabled {
    background-color: rgb(202, 210, 211) !important;
}

.start-visit-button.btn.disabled, .btn:disabled{
    opacity: .85;
}