.angle-double-icon {
    position: absolute;
    right: 5px;
    bottom: 2px;
}

.sponsorship-notes {
    width: 3ch;
    overflow: hidden;
    text-Overflow: ellipsis;
}