.AvailableRooms {
    border: 1px solid #ccc;
}

.AvailableRooms .AvailableRoomItem {
    padding: 0.5rem;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    cursor: default;
}

.AvailableRooms .AvailableRoomItem.clickable {
    cursor: pointer;
}

.AvailableRooms .AvailableRoomItem.clickable:hover {
    background-color: lightgray;
}

.AvailableRooms .AvailableRoomItem .room-info {
    font-weight: bold;
}

.AvailableRooms .AvailableRoomItem .date {
    color: gray;
}

.AvailableRooms .p-scrollpanel-content {
    margin-left: -1px;
    padding-right: 0;
}