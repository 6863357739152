.scrollable-col{
    max-height: calc(100vh - 400px) ;
    overflow-y: auto;
}

.emr-dashboard-scrollbar
{
    background: #F5F5F5;
    position: relative;
    scrollbar-color: #787878 #F5F5F5;
    scrollbar-width: thin;
}

#dim14-emr-dashboard-scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background-color: #F5F5F5;
    background-clip: content-box;
}

#dim14-emr-dashboard-scrollbar::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

#dim14-emr-dashboard-scrollbar::-webkit-scrollbar-thumb
{
    background-color: #787878;
    -webkit-border-radius: 10px;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}