
#dayPrefTable td .p-dropdown, td .p-inputtext, td .p-multiselect {
    width: 100%;
    min-width: auto;
}

#dayPrefTable td, #dayPrefTable th{
    border:1px solid #D1D1D1;
}

#dayPrefTable .first-line {
    background-color: #E0E0E0;
}

#dayPrefTable .second-line {
    background-color:#F7F7F7;
    color: #5B5E5E;;
}

#dayPrefTable tr th {
    vertical-align: middle;
}

.preference-day-row td {
    text-align: center;
}

.new-complaint-color{
    color:#0075FF;
}

.follow-up-color{
    color:#52B404;
}



 .preference-day-row {
     border-bottom:none
}

#dayPrefTable {
    border-collapse: collapse;
    border-radius: 4px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #D1D1D1; /* this draws the table border  */
    font-size:13px;
}

#dayPrefTable td {
    border-bottom: none;
    border-top:none;
}

#dayPrefTable .preference-day-row td:first-child {
    border-bottom: none;
    border-top:none;
    color:#5B5E5E;
    font-weight: bold;
    background-color:#F7F7F7;
}


#dayPrefTable .preference-day-row:last-child td:first-child{
    border-bottom-left-radius: 4px;
}
#dayPrefTable thead th:first-child{
    border-top-left-radius: 4px;
}

#dayPrefTable th.merge-cell{
    border: none;
    background-color: #F7F7F7;


}