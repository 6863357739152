.unselected {
    opacity: 0.2;
}

.selected {
    opacity: 1;
}

.selectable {
    cursor: pointer;
}

