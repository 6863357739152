.StaffMedium {
    margin-top: 30px;
}
.BiographyRichTextInput{
    width: 100%;
    min-height: 300px;
}

.UpdateBiographyModal .dim14modal {
    min-width: 700px;
}
.SkillsModal{
    width: 500px;
}

.small-screen-margin{

}

#contact-modal .MuiDialogContent-root, #contact-modal  .MuiDialog-paper {
    overflow-y: unset;
}




@media screen and (max-width: 991px) {
    .small-screen-margin {
        margin-top: 30px;
    }
}

.react-tag-input-disable-manual-entry .react-tag-input input {
    display: none;
}

.ReplaceProviderModal{
    width: 500px;
}