.RoomCalendarTopBar{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.RoomCalendarTopBar .RoomCalendarTopBarButtonGroup {
    margin: 5px;
    padding: 5px;
}

.RoomCalendarTopBar .RoomCalendarTopBarSettingsButtonGroup {
    margin: 5px;
    padding: 5px;
}

.RoomCalendarTopBar .RoomCalendarTopBarButtonGroup .RoomCalendarTopBarButton {

}

.RoomCalendarTopBar .RoomCalendarTopBarButtonGroup .RoomCalendarTopBarButton.Active {
    color: blue;
}

.RotationLogsDrawer {
    margin: auto;
    padding: 10px;
    max-height: 400px;
    overflow: auto;
}

.RotationLogsDrawerContent {
    margin: auto;
}

.RotationLogsDrawer label {
    margin: 0;
    display: block;
    font-family: monospace, monospace;
}

.RotationLogsDrawerFooter {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
    text-decoration: underline;
    text-align: center;
    font-size: 14px;
}

.MuiDrawer-paper {
    background-color: #FFF8DC !important;
}

.RoomCalendarBody {
    display: flex;
    flex-flow: row;
}

.RoomCalendarBody .RoomCalendarFilter {
    width: 300px;
}

.RoomCalendarBody .RoomCalendarFilter .MuiTypography-root {
    font-size: 14px !important;
}

.RoomCalendarBody .RoomCalendarView {
    margin: 0 5px 0 15px;
    padding: 0 5px;
    font-size: 14px;
    width: calc(100% - 310px);
    height: calc(100vh - 200px);
    overflow: auto;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarRowHeader {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    padding: 2px;
    border: 1px solid #DDD;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarRowHeader label {
    margin-top: 10px;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol {
    width: 100%;
    min-width: 100px;
    border: 1px solid #DDD;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow.RoomCalendarColHeader .RoomCalendarCol {
    padding: 5px;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol.Selected {
    border-left: 2px solid rgba(81, 203, 238, 1);
    border-right: 2px solid rgba(81, 203, 238, 1);
    /*box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 0 0 5px rgba(81, 203, 238, 1), 0 0 5px rgba(81, 203, 238, 1);*/
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper {
    width: 100%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper .RoomCalendarEvent {
    height: 100%;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 500;
    font-size: 11px;
    text-align: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper .RoomCalendarEvent.Generated {
    opacity: .5;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper .RoomCalendarEvent.Item1 {
    padding-top: 0.5rem
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper .RoomCalendarEvent.Item2 {
    padding: 0;
    font-size: 9px;
}

.RoomCalendarBody .RoomCalendarView .RoomCalendarRow .RoomCalendarCol .RoomCalendarCellWrapper .RoomCalendarEvent.Item3 {
    padding: 0;
    font-size: 10px;
}

.Item2StaffName{
    font-size: 9px;
    align-self: center;
    margin-left : 2px;
}

.Item2Time{
    font-size: 8px;
    align-self: center;
    margin-left : 3px;
}

.Item1Time{
    font-size: 9px;
}