.disc-radiobutton-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #5F5959;
}

.bordered-radiobutton {
    cursor: pointer;
    border-radius: 25px;
    border: 1px solid #C9C9C9;
    padding: 4px 8px;
    margin: 4px;
    text-align: center;
}

.bordered-radiobutton.selected {
    border: 1px solid #7BAC16;
    color: white;
    background-color: #7BAC16;
}
