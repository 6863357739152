.user-menu-wrapper {
    display: flex;
    align-items: center;
}

.user-menu-wrapper label {
    margin: 0 5px;
    min-width: fit-content;
}

.user-menu-wrapper .rounded-circle {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.user-menu-wrapper .rounded-circle:hover {
    border: 1px solid #EEE;
    box-sizing: border-box;
}

.user-menu-wrapper #user-menu {
    z-index: 999;
}

.user-menu-wrapper .rounded-circle.default {
    padding: 2px;
    border: 1px solid #DDD;
    box-sizing: border-box;
}