.apploading {
    cursor: wait;
    opacity: 0.6;
}

.loader {
    border: 7px solid #f3f3f3;
    border-top: 7px solid #3498db;
    border-bottom: 7px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-component-wrapper {
    z-index: 2000;
    position: fixed;
    opacity: 1;
    bottom: 50%;
    right: 48%;
}

.loading-page-cover {
    z-index: 1500;
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
}