.InsuranceTabs {
    border: none;
}

.p-column-title{
    font-size:12px;
}

.p-datatable tr > td{
    font-size:12px;
}
