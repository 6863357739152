.dim14-form-base {
    width: 100%;
}

.dim14-form-row {
    display: flex;
}

.dim14-form-input {
    display: flex;
}

div.dim14-form-input > *:not(.dim14-colon) {
    float: left;
    flex-grow: 100;
    width: 100%;
}

div.dim14-base-input > *:not(.p-checkbox):not(button):not(.dim14checkboxinput) {
    float: left;
    flex-grow: 100;
    width: 100%;
}

div.dim14-base-input > * > .p-autocomplete-input {
    float: left;
    flex-grow: 100;
    width: 100%;
}

.p-autocomplete {
    width: 100% !important;
}

div.dim14-base-input input :not(.Dim14RadioDiv) {
    float: left;
    flex-grow: 100;
    width: 100%;
    min-height: 30px;
}

div.dim14-base-input > * > * > .react-datepicker-ignore-onclickoutside, div.dim14-base-input .react-datepicker__input-container > input {
    float: left;
    flex-grow: 100;
    width: 100%;
    min-height: 30px;
}

.dim14-col-12 {
    width: 100%;
}

.dim14-col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.dim14-col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.dim14-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.dim14-col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.dim14-col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.dim14-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.dim14-col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.dim14-col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.dim14-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.dim14-col-2 {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}

.dim14-col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.dim14-col-0 {
    flex: 0 0 0%;
    max-width: 0%;
}

.dim14-colon {
    width: 5px;
}
