.notification-container {
    max-width: 450px;
    max-height: 400px;
    background: #ffffff;
    padding: 0;
    position:relative;
    overflow-y:auto;

}

.notification-item {
    font-size: 13px;
    color: #4d4d4d;
    padding: 8px 8px 8px 16px;
    display: flex;
    justify-content: space-between;
}

.notification-icon {
    cursor: pointer;
    padding: 7px 8px;
    margin-right: 20px;
    border-radius: 35px;
}

.notification-icon:hover {
    background-color: #EEEEEE;
}
.notification-footer{
    background-color:#fff;
    border-top:1px solid #ccc;
    padding:16px;
}

.notification-header{
    background-color:#fff;
    border-bottom:1px solid #ccc;
    padding:16px;
}

.notification-header h4 {
    line-height: 1;
    margin:0;
    color:#212529;
}

.pop-over{
    overflow:hidden;
}