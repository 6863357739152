.date-select-root {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 400px;
}

.direction-button {
    padding: 1px 7px;
    border: 1px solid #C9C9C9;
    border-radius: 8px;
    background: white;
    font-weight: lighter;
}

.date-select-div {
    width: fit-content;
    border: 1px solid #C9C9C9;
    background: white;
    border-radius: 4px;
    height: 28px;
    display: flex;
    align-items: center;
}

.date-select-text {
    padding: 0px 2px;
}
