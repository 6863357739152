.upper-canvas {
}

.cross-cursor {
    cursor: url('cross-cursor-pointer.png'), pointer !important;
}

.arrow-cursor {
    cursor: url('arrow-cursor-pointer.png'), pointer !important;
}

.circle-cursor {
    cursor: url('circle-cursor-pointer.png'), pointer !important;
}

.default-cursor {
    cursor: default !important;
}