.dim14-timepicker-container {
    position: relative;
    width: fit-content;
}

.dim14-calendar-clear-icon {
    right: 8px;
    top: 6px;
    position: absolute;
}

.dim14-timepicker-container .p-timepicker {
    text-align: center;
    padding: .5em 0;
}
