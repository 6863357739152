.PatientSelectInput{
    margin-left: 15px;
    margin-top: -2px;
    min-width: 200px;
    max-width: 320px;
    max-height: 20px;
    font-size: 13px;
}

.AddBillingAutoComplete > input {
    width: 100%;
    max-height: 30px;
}

.BillingScreen{
    font-size: 12px;
}

.BillingScreen .dim14textinput{
    max-height: 30px !important;
}

.BillingScreen .btn{
    border-radius: 5px !important;
}

.BillingScreen .btn-success{
    background-color: #7AAE00 !important;
    border-color: #7AAE00 !important;
}

.BillingScreen .body .p-inputtext{
    border:1px solid #ccc !important;
}

.BillingScreen .p-dropdown .p-dropdown-label{
    max-height: 30px;
    font-size: 12px;
}

.billingSummaryHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 14px;
    color: #7AAE00;
}

.billingSummary {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
}
.billingSummaryRow {
    border-top: 1px solid #ececec;
    padding-top: 2px;
}

.p-datatable .p-datatable-wrapper .p-datatable-tbody .disabledRow {
    background-color: #EBEBEB;
}

.p-datatable th {
    font-size: 14px !important;
}

.p-datatable-tbody {
    font-size: 12px !important;
}

.BillingNotes {
    width: 100%;
    min-height: 50px;
}
