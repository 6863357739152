.rbc-current-time-indicator {
    background-color: red !important;
    height: 2px !important;
    z-index: 11 !important;
}

.rbc-current-time-indicator .rbc-current-time-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background: red;
    color: white;
    font-size: small;
    border-radius: 5px;
}

.rbc-toolbar-actions {
    display: flex;
    margin-right: 0.5rem;
}

.rbc-events-container .rbc-event.ignore-overlaps {
    width: 100% !important;
    left: 0 !important;
}

.rbc-events-container .rbc-background-event.ignore-overlaps {
    width: 100% !important;
    left: 0 !important;
}
