

.patient-link{
    font-size:1.25rem;
    white-space: nowrap;
    color:#000;
    font-weight: bold;
    display:block;
}
.patient-link:hover{
    text-decoration: none;
    color:#7AAE00;
}
