.reference-panel{

}

@media screen and (max-width: 767px) {
    .reference-panel {
        margin-top: 20px;
    }
}

.add-reference-modal{
    min-width: 520px;
}