.pos-neg-container {
    position:absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.pos-neg-col {
    min-width: 25px;
    position: relative;
}


.pos-neg-col-text {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pos-selected {
    color: transparent;
    text-shadow: 0 0 0 rgb(126,174,28);
    background-color: rgb(215,230,184);
}

.neg-selected {
    color: transparent;
    text-shadow: 0 0 0 rgb(213,63,63);
    background-color: rgb(242,197,197);
}

.pointer {
    cursor: pointer
}