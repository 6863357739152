.visit-reports{
    margin: auto;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
}

.visit-reports-table {
    width: 100%;
    word-wrap: break-word;
    table-layout:fixed;
}

.visit-reports-table tr {
    border: 1px solid beige !important;
}

.visit-reports-table th {
    border: 1px solid beige !important;
    padding-left: 5px;
    padding-right: 5px;
}

.visit-reports-table td {
    border: 1px solid beige !important;
}

.visit-reports-content{
    width: 100%;
}

.visit-reports-content ol {
    font-weight: bold;
    padding-left: 0px !important;
    list-style-type: none !important;
}

.visit-reports-content li {
    list-style-type: none !important;
}

.visit-reports-multiple-content-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
}

.display-block span{
    display: block;
    margin-bottom: 10px;
}

.element-to-show{
    display: none;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
    border: none;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #6DBA17 !important;
    background-color: #6DBA17 !important;
    color: #ffffff;
}

.visit-reports-table-col-1 {
    width: 8.333333% !important;
}

.visit-reports-table-col-2 {
    width: 16.666667% !important;
}

.visit-reports-table-col-7 {
    width: 58.333333% !important;
}
