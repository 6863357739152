.progress {
    background-color: #f5f5f5;
}

.progress-bar {
    background-color: #84be56;
}

/*
.dim14textinput {
  padding-left: 5px;
  width: 100%;
  height: 27px !important;
} */

/* .Dim14SelectInput {
  width: 100%;
  height: 27px !important;
  font-size: 12px;
} */

.WizardModal {
    background-color: #f5f5f5;
    min-width: 100%;
    min-height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
}

.WizardLabel {
    font-size: 1.2vmin;
    font-weight: bold;
}

.FormLine {
    padding-top: 6px;
    padding-bottom: 6px;
}

.FormSectionHeader {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
}

.MuiDialogContent-root {
    padding: 0;
    min-width: 100% !important;
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif !important;
}

.col-form-label {
    font-size: 14px;
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif;
    font-weight: 500;
}

body .p-dropdown .p-dropdown-label {
    font-size: inherit;
}

.p-dropdown-filter-container {
    width: 100%;
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif !important;
}

.p-dropdown,
.p-inputtext {
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif !important;
    font-size: 1rem !important;
    padding: 0 8px;
    color: #495057 !important;
}

.panel {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
}

.bg-grey {
    background-color: #dee2e6;
}

.date-input input {
    font-size: 1rem !important;
    padding: 8px 15px !important;
    color: #495057;
    font-weight: 400;
    font-family: Lato, Roboto, Arial, Helvetica, sans-serif !important;
}

.release-of-medical-records-large-text-field input[class$=dim14textinput] {
    height: 60px !important;
}

.release-of-medical-records-default-text-field input[class$=dim14textinput] {
    height: 35px !important;
}

.ultrasound-diagnostic-large-text-field input[class$=dim14textinput] {
    height: 80px !important;
}

.medical-photography-consent-large-text-field input[class$=dim14textinput] {
    height: 60px !important;
    width: 100% !important;
}

body .p-calendar .p-inputtext {
    padding-left: 0.5rem !important;
}

.wizard-modal-grey {
    background-color: #f5f5f5;
}

.canvas-container {
    background-color: #FFFFFF;
    margin: 0 auto;
}

.undo-button {
    position: absolute;
    top: 0;
    right: 0;
}

.Dim14RadioDiv {
    margin-right: 8px;
    white-space: nowrap;
}

.dim14-base-input {
    display: flex;
}

.signature-canvas canvas {
    background-color: #fff;
}

.informed-consent-text-input .dim14textinput {
    width: 75% !important;
}

.public-allergy-dropdown .PowerSelect{
    height: 100%;
}

.public-allergy-dropdown .dim14-base-input{
    height: 100%;
}

.arabic-form .form-check-label{
    padding-right: 0.5rem;
}
