.gradient-area {
    background: #EDF6DB;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #F6DBDB, #EDF6DB);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #F6DBDB, #EDF6DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.reverse-colored-gradient-area {
    background: #EDF6DB;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #EDF6DB, #F6DBDB);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #EDF6DB, #F6DBDB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient-slider-input {
    width: calc(100% - 2px);
}

.gradient-slider span[class*="MuiSlider-root"] {
    padding-top: 0px;
    margin-bottom: 10px;
    color: lightgray;
}

.gradient-slider span[class*="MuiSlider-markLabel"] {
    top: 5px;
}

.gradient-slider span[class*="PrivateValueLabel-circle"] {
    background-color: white;
}

.gradient-slider span[class*="MuiSlider-thumb"] {
    color: white;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.15)!important;
}

.mild-col {
    height: 100%;
    flex: 0 0 40%;
    max-width: 40%;
}

.mild-col div {
    margin-top: 6px;
    color: #7BAC16;
}

.moderate-col {
    height: 100%;
    border-left: 1px white solid;
    flex: 0 0 30%;
    max-width: 30%;
    border-right: 1px white solid;
}

.moderate-col div {
    margin-top: 6px;
    color: #E68040;
}

.severe-col {
    height: 100%;
    flex: 0 0 30%;
    max-width: 30%;
}

.severe-col div {
    margin-top: 6px;
    color: #AC1616;
}

.quarter-col {
    height: 100%;
    border-left: 1px white solid;
    flex: 0 0 25%;
    max-width: 25%;
    border-right: 1px white solid;
}

.z-index {
    z-index: 0;
}

.label-text {
    color: black;
}