.dim14-calendar-container {
    position: relative;
    width: fit-content;
}

.dim14-calendar-clear-icon {
    right: 8px;
    top: calc(50% - 8px);
    position: absolute;
}

.dim14-calendar-calendar-icon {
    right: 8px;
    top: calc(50% - 8px);
    position: absolute;
}

.dim14-calendar.p-datepicker {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    padding: 0;
    width: 24em;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-title select {
    background: none;
    color: white;
    font-weight: bold;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-title select option {
    background: none;
    color: black;
    font-weight: normal;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    box-shadow: none;
    outline: none;
    border-color: white;
}

.dim14-calendar.p-datepicker.p-shadow {
    border: 0 none;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dim14-calendar.p-datepicker table {
    table-layout: fixed;
    border: none;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0;
    width: 100%;
    font-size: .9em;
}

.dim14-calendar.p-calendar .p-datepicker {
    min-width: fit-content;
}

.dim14-calendar.p-datepicker {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0;
    width: 24em;
}

.dim14-calendar.p-datepicker .p-datepicker-header {
    position: relative;
    color: #ffffff;
    padding: 0.714em 1em;
    background: #15922f;
    border: 1px solid #15922f;
    border-radius: 2px 2px 0 0;
}

.dim14-calendar.p-datepicker .p-datepicker-group {
    padding: 0;
    border-width: 0px;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-title {
    margin: 0;
    padding: 0;
    line-height: 1.8;
}

.dim14-calendar.p-datepicker .p-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-prev {
    cursor: pointer;
    top: 12px;
    left: 0.5em;
    font-size: 1.5em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    color: #ffffff;
    box-shadow: none;
}

.dim14-calendar.p-datepicker .p-datepicker-header .p-datepicker-next {
    cursor: pointer;
    top: 12px;
    font-size: 1.5em;
    right: 0.5em;
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    box-shadow: none;
}

.dim14-calendar.p-datepicker table thead tr {
    color: #ffffff;
    background: #29a744;
}

.dim14-calendar.p-datepicker .p-timepicker {
    background-color: #29a744;
    color: #ffffff;
    border: 1px solid #29a744;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.dim14-calendar.p-timepicker {
    text-align: center;
    padding: .5em 0;
}

.dim14-calendar.p-datepicker table td.p-datepicker-today > span.p-highlight {
    background-color: #E91E63;
    color: #ffffff;
    box-shadow: none;
    outline: none;
}

.dim14-calendar.p-datepicker table td > span.p-highlight {
    background-color: #E91E63;
    color: #ffffff;
    box-shadow: none;
    outline: none;
}

.dim14-calendar.p-datepicker table td.p-datepicker-today > span {
    color: #212121;
    background-color: #ffffff;
    border: 1px solid #E91E63;
}

.dim14-calendar.p-datepicker table td > span {
    padding: 0;
    margin: 0;
    text-align: center;
    color: #212121;
    display: inline-block;
    height: 3em;
    width: 3em;
    line-height: 3em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}