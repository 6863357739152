.PatientSearchAutocomplete > input {
    width: 100%;
}

.PatientSearchAutocomplete {
    width: 100% !important;
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__heading {
    height: 40px;
    background-color: #f4f4f4;
}

.accordion__button {
    padding-top: 5px;
    color: #444;
    cursor: pointer;
    padding-left: 18px;
    width: 100%;
    height: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

.accordion__panel {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.stafflistname {
    display: inline-block;
    max-width: 80%;
    font-size: 14px;
}

.stafflistcheckbox {
    display: inline-block;
    float: right;
    paddingRight: 20px;
}

.AppointmentFilter .MuiTypography-root {
    font-size: 14px !important;
}
