
.dim14EMRHeaderPanel {
    padding-bottom: 20px;
}

.dim14EMRHeaderPanel .card {
    height: 160px;
    margin: 5px;
    overflow: hidden;
}

.dim14EMRHeaderPanel div[class=row] {
    height: 100%;
    margin: 0;
}

.dim14EMRHeaderPanel div[class^='col'], div[class*=' col']{
    padding: 0;
}

.dim14EMRHeaderPanel div[class$=outer] {
}

.dim14EMRHeaderPanel div[class$=inner] {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    max-width: 150px;
}

.vertical-align-left-center {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: left;
}
