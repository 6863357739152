.insurance-panel{

}

.insurance-type-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -2px;
    color: white;
    font-weight: bold;
    cursor: pointer
}